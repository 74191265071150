import createChannel from '../../src/cable';

let connectionCallback;
let playerUpdateCallback;

createChannel({ channel: 'PlayerChannel' }, {
  connected: () => { if (connectionCallback) { connectionCallback.call(this, true); } },
  disconnected: () => { if (connectionCallback) { connectionCallback.call(this, false); } },
  received: (data) => { if (playerUpdateCallback) { playerUpdateCallback.call(this, data); } },
});

export default function setupPlayerPorts(ports) {
  if (ports.onlineStatusChanged) {
    connectionCallback = ((data) => {
      ports.onlineStatusChanged.send(data);
    });
  }

  if (ports.playerUpdate) {
    playerUpdateCallback = ((data) => {
      ports.playerUpdate.send(data);
    });
  }
}
